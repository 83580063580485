.tabAccordion {
    margin: .6rem;
    padding-bottom: 3rem;
    overflow-y: scroll;
    height: calc(100vh - (60px + 60px));

    &__item {
        margin-bottom: 1rem;
        border: .01rem solid #DFEDF5;
        //  border-radius: 1rem;
        opacity: 1;
        background-color: #ffffff;

        &.accordion-item:not(:first-of-type) {
            border-top: .01rem solid #DFEDF5;
        }

        &__header {
            border-radius: 0rem;
             button {
                //border-radius: 0rem;
                padding: 0.8rem;
             }
        }
    }

    
}




.tabAccordion {
    &__accordion {
        margin: 10;

        &__accordionCard {
            margin: 5px;
            border-radius: 15px;
            opacity: 1px;
            background-color: #ffffff;
            padding: 10px 18px 7px 18px;
            border-style: solid;
            border-width: 1.5px;
            border-color: #DFEDF5;

            &__toggle {
                height: 40;
                justify-content: center;
                padding: 5;
                background-color: #ffffff;
                cursor: pointer;
                border: none;

                &__toggleSpan1 {
                    font-weight: "700"
                }

                &__toggleSpan2 {
                    position: absolute;
                    right: 0;

                    &__bsChevronExpand {
                        width: 18;
                        height: 18;
                    }
                }
            }

            &__newsAndFeedbackSubtitle {
                display: inline-block;
                    font-size: 14px;
                    font-weight: 400;
                    color: #6489a0;
                    margin-left: 5px;
            }
        }
    }
}