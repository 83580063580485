.stAddButton {
    height: 1.75rem;
    margin-left: 0.3125rem;
    margin-right: 0.3125rem;

    &__input {
        font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
        box-shadow: none;
        border: 1px solid rgba(34, 36, 38, .15);
        background: #fff;
        border-color: #e0e1e2;
    }

    &__button {
        cursor: pointer;
        min-height: 1em;
        outline: 0;
        border: none;
        vertical-align: baseline;
        background: #e0e1e2 none;
        color: rgba(0, 0, 0, .6);
        font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
        text-transform: none;
        text-shadow: none;
        font-weight: 700;
        line-height: 1em;
        text-align: center;
        box-shadow: none;
    }
}