.myProfile {
  height: calc(100vh - 60px);
  overflow-y: auto;
}


.add-user {
  &.main-container {}

  &.sidebar-container {
    // height: calc(100vh - 5.3125rem);
    border-right: 1px solid #DFEDF5;
    display: flex;
    flex-direction: column;
  }

  &.form-container {
    margin-top: 3rem;
  }

  &.profile-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
  }

  &.profile-name {
    color: #131e27;
    font-size: 24px;
    font-weight: 700;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 1.375rem;
  }
}