.stSearchBar{
    // width: width ? width : "auto";
                    margin-left: 15px;
                    height: 48px;
                    outline: none;
                    border: 0px;
                    background-color: #ffffff;
                    // align-self: $align-self;
                    border-radius: 12px;
                    padding-right: 24px;
                    padding-left: 24px;
                    font-size: 16px;
}