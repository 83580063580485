.feedbackCardElement {
    border-radius: .625rem;
    font-size: 14px;
    margin-Bottom: .375rem;
    cursor: pointer;
    padding: 10px; 

    &__feedBackEmail {
        color: #354052;
        font-weight: 700;
        margin-left: .25rem;
    }

    &__feedbackData {
        color: #6489a0;
        font-weight: 400;
    }

    &__bsChatSquareText {
        margin-top: .375rem;
        display: flex;
        justify-content: flex-end;
    }
}